var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;

var PageView = Backbone.View.extend({
    events: {},

    initialize: function(opts){
        this.$el = opts.$el;
        this.el = this.$el[0];

        this.model = new MaisonNoir.Model();

        this.pubSub = {};
        _.extend(this.pubSub, Backbone.Events);
        this.pubSub.on('page:loaded', this.pageLoaded, this);

        this.resize();
        Popular.pubSub.on('app:resize', this.resize, this);

        this.initThis();
    },

    initThis: function(){
        return false;
    },

    resize: function(){},

    animateIn: function(){
        var that = this;
        var promise = $.Deferred();
        
        this.$el.addClass('viewIn');
        setTimeout(function(){
            promise.resolve();
            that.pubSub.trigger('page:loaded');
        }, 600);
        return promise.promise();
    },

    animateOut: function(){
        var promise = $.Deferred();

        this.$el.removeClass('viewIn pageLoaded');
        setTimeout(function(){
            promise.resolve();
        }, 600);
        return promise.promise();
    },

    pageLoaded: function(){
        this.$el.addClass('pageLoaded');
    },

    remove: function(){
        this.pubSub.trigger('page:removed');
        this.undelegateEvents();
        this.$el.remove();
    }
});

module.exports = PageView;