var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;

var pages = require('./pages');
var HeaderView = require('./components/header.js');

var AppView = Backbone.View.extend({

    el: 'body',

    initialize: function(){
    	_.bindAll(this, 'resize', 'scroll');
        $(window).on('resize', this.resize);
        $(window).on('scroll', this.scroll);

    	this.model = new Backbone.Model({
    		'headerHidden': false
    	});
    },

    render: function(){
    	this.headerView = new HeaderView();

    	this.resize();
    	this.scroll();
    },

    resize: _.throttle(function(){
        this.w = $(window).width();
        this.h = $(window).height();
    }, 16),

    scroll: function(){
    	var scrollPos = this.$el.scrollTop();

    	this.model.set('headerHidden', scrollPos > this.scrollPos /*&& scrollPos >= options.headerHeight && scrollPos < this.$el.height() - this.h - options.headerHeight */);
    	
    	this.scrollPos = scrollPos;
    }

});

module.exports = AppView;