var $ = require('jquery');
var AppView = require('./appView.js')

var Popular = {};

$(document).ready(function(){
	Popular.view = new AppView();
	Popular.view.render();
});

global.Popular = Popular;