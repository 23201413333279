var $ = require('jquery');
var _ = require('underscore');
var Backbone = require('backbone');
Backbone.$ = $;

var HeaderView = Backbone.View.extend({
    el: 'header',

     events: {
        'mouseenter': 'enterHeader',
        'mouseleave': 'leaveHeader'
     },

    initialize: function(){
        this.model = new Backbone.Model();

        this.listenTo(Popular.view.model, {
            'change:headerHidden': this.toggleHidden
        }, this);
    },

    toggleHidden: function(model, hidden){
        this.$el.toggleClass('hidden', hidden);
    },

    enterHeader: function(){
        var isHidden = Popular.view.model.get('headerHidden');
        if(isHidden){
            this.model.set('shouldHideAgain', true);
            Popular.view.model.set('headerHidden', false);
        }
    },

    leaveHeader: function(){
        var shouldHideAgain = this.model.get('shouldHideAgain');
        if(shouldHideAgain){
            this.model.set('shouldHideAgain', false);
            Popular.view.model.set('headerHidden', true);
        }
    },

    delegateHeader: function(path){
        
    }
});

module.exports = HeaderView;